import { ResponsiveBarCanvas } from '@nivo/bar';
import { SkewChartDatum } from './SkewsTable';
import { chartCommonProps } from '../Lifts/LiftTable';

interface Props {
  skewData: SkewChartDatum[] | null;
}

export const SkewsChart = ({ skewData }: Props) => {
  return (
    <ResponsiveBarCanvas
      {...chartCommonProps}
      // animate={false}
      isInteractive={false}
      enableLabel={false}
      // defs={[
      //   linearGradientDef(
      //     'gradientPositive',
      //     [
      //       { offset: 0, color: theme.colors.successForeground },
      //       { offset: 100, color: theme.colors.successBackground, opacity: 20 },
      //     ],
      //     {
      //       gradientTransform: 'rotate(90 0.5 0.5)',
      //     }
      //   ),
      //   linearGradientDef(
      //     'gradientNegative',
      //     [
      //       { offset: 0, color: theme.colors.errorForeground, opacity: 100 },
      //       { offset: 100, color: theme.colors.errorBackground, opacity: 20 },
      //     ],
      //     {
      //       gradientTransform: 'rotate(-90 0.5 0.5)',
      //     }
      //   ),
      // ]}
      // fill={[
      //   {
      //     match: (d) => !!(d.data.value && d.data.value > 0),
      //     id: 'gradientPositive',
      //   },
      //   { match: '*', id: 'gradientNegative' },
      // ]}
      enableGridX
      axisBottom={null}
      enableGridY={false}
      maxValue={100}
      minValue={-100}
      data={skewData || []}
      keys={['skew']}
      label={''}
      padding={0.6}
      layers={[
        'grid',
        'axes',
        // ({ bars, xScale }) =>
        //   bars.map((bar) => (
        //     <rect
        //       key={bar.key}
        //       x={xScale(-100)}
        //       y={bar.y}
        //       width={xScale(0) - xScale(-100)}
        //       height={bar.height}
        //       fill={'#E8304A0D'}
        //     />
        //   )),
        (ctx, { bars, xScale }) => {
          bars.forEach((bar) => {
            const x = xScale(-100);
            const y = bar.y;
            const width = xScale(0) - xScale(-100);
            const height = bar.height;
            ctx.fillStyle = 'rgba(232, 48, 74, 0.05)'; // Equivalent to '#E8304A0D'
            ctx.fillRect(x, y, width, height);
          });
        },
        // ({ bars, xScale }) =>
        //   bars.map((bar) => (
        //     <rect
        //       key={bar.key}
        //       x={xScale(0)}
        //       y={bar.y}
        //       width={xScale(100) - xScale(0)}
        //       height={bar.height}
        //       fill={'#2B7D510D'}
        //     />
        //   )),
        (ctx, { bars, xScale }) => {
          bars.forEach((bar) => {
            const xStart = xScale(0);
            const rectWidth = xScale(100) - xStart;
            ctx.fillStyle = '#2B7D510D';
            ctx.fillRect(xStart, bar.y, rectWidth, bar.height);
          });
        },
        'bars',
        // 'markers',
        'legends',
      ]}
    />
  );
};
