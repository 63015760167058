import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useCallback } from 'react';

export const useExport = () => {
  const exportDivToPdf = useCallback(
    async (divId, pdfFileName = 'sample.pdf') => {
      const element = document.getElementById(divId);

      if (!element) {
        console.error(`Element with id "${divId}" not found.`);
        return;
      }

      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'in', 'a4'); // A4 page in inches (8.27 x 11.69)

      const margin = 0.5; // Define margin in inches
      const pdfWidth = pdf.internal.pageSize.getWidth(); // Width of the PDF
      const pdfHeight = pdf.internal.pageSize.getHeight(); // Height of the PDF

      const availableWidth = pdfWidth - 2 * margin; // Width available after margins
      const availableHeight = pdfHeight - 2 * margin; // Height available after margins
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = availableWidth;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // Scaled image height

      let heightLeft = imgHeight;
      let position = margin;

      // Add the first page
      pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
      heightLeft -= availableHeight;

      // Add more pages if necessary
      while (heightLeft > 0) {
        position = heightLeft - imgHeight + margin;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= availableHeight;
      }

      pdf.save(pdfFileName);
    },
    []
  );

  const exportDivToImage = useCallback(
    async (divId, imageFileName = 'sample.png') => {
      const element = document.getElementById(divId);
      if (!element) {
        console.error(`Element with id "${divId}" not found.`);
        return;
      }

      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = imgData;
      link.download = imageFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    []
  );

  return { exportDivToPdf, exportDivToImage };
};
