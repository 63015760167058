import IncrementalityMarker from '../IncrementalityMarkers/IncrementalityMarkers';
import IncrementalityTable from '../Tables/IncrementalityTable/IncrementalityTable';
import LegendPoint from 'assets/legend-point-primary.svg';
import LegendSquare from 'assets/legend-square-tertiary.svg';
import MeasurementChart from 'views/Measurement/MeasurementChart';
import MeasurementGhostLoader from 'views/Measurement/MeasurementGhostLoader';
import MeasurementIncrementalityChart from 'views/Measurement/MeasurementIncrementalityChart';
import React from 'react';
import StatCard from 'components/StatCard/StatCard';
import incrementalityQuery from 'operations/queries/incrementality.query';
import useMe from 'hooks/useMe/useMe';
import useSearchParams from 'hooks/useSearchParams';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  Alert,
  Button,
  ButtonGroup,
  CardTypes,
  Grid,
} from '@klover/attain-design-system';
import {
  IncrementalityDailyLift,
  IncrementalityQuery,
  IncrementalityQueryVariables,
  LiftSignificance,
  MeasurementOverviewDualAxis,
  MeasurementOverviewMetrics,
} from 'types/graphql/generated';
import {
  MeasurementSearchParams,
  dataChart,
} from 'views/Measurement/MeasurementDashboard';
import {
  getDataChartIncrementality,
  getDataChartIncrementalityCi,
} from 'utils/Measurement/dataCharts';
import { getMeasurementIncrementalityInputFromSearchParams } from 'utils/Measurement/measurementInputFromUrlParams';
import { useLazyQuery } from '@apollo/client';
import * as Styled from '../Measurement.styles';

// temporary mapping until deperacated enums are removed.
export const significanceMap = {
  // Significantly positive variants
  WITHIN_CONFIDENCE_INTERVAL_AND_POSITIVE:
    LiftSignificance.SIGNIFICANTLY_POSITIVE,
  [LiftSignificance.SIGNIFICANTLY_POSITIVE]:
    LiftSignificance.SIGNIFICANTLY_POSITIVE,

  //Not significant variants
  NOT_WITHIN_CONFIDENCE_INTERVAL: LiftSignificance.NOT_SIGNIFICANT,
  [LiftSignificance.NOT_SIGNIFICANT]: LiftSignificance.NOT_SIGNIFICANT,

  //NL variants
  WITHIN_CONFIDENCE_INTERVAL_AND_NEGATIVE: LiftSignificance.NO_LIFT_DETECTED,
  [LiftSignificance.NO_LIFT_DETECTED]: LiftSignificance.NO_LIFT_DETECTED,
};

interface Props {
  overviewLoading: boolean;
  metrics: MeasurementOverviewMetrics | undefined;
  impressions: MeasurementOverviewDualAxis | undefined;
  reach: MeasurementOverviewDualAxis | undefined;
  dataChartImpressions?: dataChart[];
  dataChartReach?: dataChart[];
}

export const MeasurementIncrementality = ({
  overviewLoading,
  metrics,
  impressions,
  reach,
  dataChartImpressions,
  dataChartReach,
}: Props) => {
  const me = useMe();
  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const uiTransactionLift = useUiFlag('ui_incrementality_transaction_lift');
  const ffShowTransactionLift =
    uiTransactionLift.isReady && uiTransactionLift.enabled;
  React.useEffect(() => {
    window.analytics.page('Page view: Measurement', {
      ...me,
    });
  }, []);

  const [chart, setChart] = React.useState<{
    chartType: 'sales' | 'transaction';
    lifts: IncrementalityDailyLift[] | null;
  }>({ chartType: 'sales', lifts: null });

  const [getIncrementalityData, { data, loading }] = useLazyQuery<
    IncrementalityQuery,
    IncrementalityQueryVariables
  >(incrementalityQuery);

  const incrementality = React.useMemo(
    () =>
      data?.incrementality.__typename === 'IncrementalitySuccess'
        ? data.incrementality
        : null,
    [data]
  );

  // const incrementality = React.useMemo(() => FAKEINCDATA, [data]);

  const dataChartIncrementality = React.useMemo(
    () =>
      getDataChartIncrementality(
        chart.lifts,
        incrementality?.fowEndDate || chart.lifts?.at(0)?.date
      ),
    [incrementality, chart]
  );
  const dataChartIncrementalityCiBounds = React.useMemo(
    () =>
      getDataChartIncrementalityCi(
        chart.lifts,
        incrementality?.fowEndDate || chart.lifts?.at(0)?.date
      ),
    [incrementality, chart]
  );

  const showTrendedChart =
    incrementality?.transactionLiftTrendedChart ||
    incrementality?.salesLiftTrendedChart;

  React.useEffect(() => {
    const incrementalityInput =
      getMeasurementIncrementalityInputFromSearchParams(
        searchParams.campaignId,
        searchParams
      );
    if (incrementalityInput) {
      getIncrementalityData({
        variables: incrementalityInput,
        onCompleted(data) {
          if (data.incrementality.__typename === 'IncrementalitySuccess') {
            if (
              data.incrementality.salesLiftTrendedChart &&
              (!chart ||
                chart.chartType === 'sales' ||
                !data.incrementality.transactionLiftTrendedChart)
            ) {
              setChart({
                chartType: 'sales',
                lifts: data.incrementality.salesLiftTrendedChart,
              });
            } else if (data.incrementality.transactionLiftTrendedChart) {
              setChart({
                chartType: 'transaction',
                lifts: data.incrementality.transactionLiftTrendedChart,
              });
            }
          }
        },
      });
    }
  }, [searchParams]);

  if (loading || overviewLoading) {
    return <MeasurementGhostLoader />;
  }

  return incrementality ? (
    <>
      <Grid item xs={12}>
        <Styled.SectionLabel>Incrementality metrics</Styled.SectionLabel>
      </Grid>

      <Grid item xs={12} sm={ffShowTransactionLift ? 3 : 6}>
        <StatCard
          data-intercom-target={'incrementality-sales-lift'}
          title={'Incremental sales lift (total)'}
          detail={
            significanceMap[incrementality.salesLift.significance] !==
              LiftSignificance.NO_LIFT_DETECTED &&
            incrementality.salesLift.value?.title
              ? incrementality.salesLift.value.title
              : 'N/A'
          }
          type={CardTypes.Inverted}
        >
          <Styled.StatCardBody>
            <IncrementalityMarker
              significance={incrementality.salesLift.significance}
              withText
            />
          </Styled.StatCardBody>
        </StatCard>
      </Grid>
      {ffShowTransactionLift && (
        <Grid item xs={12} sm={3}>
          <StatCard
            data-intercom-target={'incrementality-sales-driven'}
            title={'Incremental transaction lift'}
            detail={
              significanceMap[incrementality.transactionLift.significance] !==
                LiftSignificance.NO_LIFT_DETECTED &&
              incrementality.transactionLift.value?.title
                ? incrementality.transactionLift.value.title
                : 'N/A'
            }
          >
            <Styled.StatCardBody>
              <IncrementalityMarker
                significance={incrementality.transactionLift.significance}
                withText
              />
            </Styled.StatCardBody>
          </StatCard>
        </Grid>
      )}

      <Grid item xs={12} sm={3}>
        <StatCard
          data-intercom-target={'incrementality-sales-driven'}
          title={'Incremental sales driven'}
          detail={
            significanceMap[
              incrementality.incrementalSalesDrivenSignificance
            ] !== LiftSignificance.NO_LIFT_DETECTED &&
            incrementality.incrementalSalesDriven?.title
              ? incrementality.incrementalSalesDriven.title
              : 'N/A'
          }
        >
          <Styled.StatCardBody>
            <IncrementalityMarker
              significance={incrementality.incrementalSalesDrivenSignificance}
              withText
            />
          </Styled.StatCardBody>
        </StatCard>
      </Grid>

      <Grid item xs={12} sm={3}>
        <StatCard
          title={'Incremental ROAS'}
          detail={
            significanceMap[incrementality.incrementalRoasSignificance] !==
              LiftSignificance.NO_LIFT_DETECTED &&
            incrementality.incrementalRoas?.title
              ? incrementality.incrementalRoas.title
              : 'N/A'
          }
          data-intercom-target="incrementality-roas"
        />
      </Grid>

      {showTrendedChart && (
        <Grid item xs={12}>
          <StatCard
            title={
              <Grid container justifyContent="space-between">
                <h3>Incremental Lift - Daily</h3>
                {ffShowTransactionLift && (
                  <ButtonGroup>
                    {!!incrementality.salesLiftTrendedChart && (
                      <Button
                        variant={
                          chart.chartType === 'sales' ? 'contained' : 'outlined'
                        }
                        onClick={() => {
                          setChart({
                            chartType: 'sales',
                            lifts: incrementality.salesLiftTrendedChart ?? null,
                          });
                        }}
                      >
                        Sales lift
                      </Button>
                    )}
                    {!!incrementality.transactionLiftTrendedChart && (
                      <Button
                        variant={
                          chart.chartType === 'transaction'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => {
                          setChart({
                            chartType: 'transaction',
                            lifts:
                              incrementality.transactionLiftTrendedChart ??
                              null,
                          });
                        }}
                      >
                        Transaction lift
                      </Button>
                    )}
                  </ButtonGroup>
                )}
              </Grid>
            }
          >
            <MeasurementIncrementalityChart
              data={dataChartIncrementality}
              ciBounds={dataChartIncrementalityCiBounds}
            />
          </StatCard>
        </Grid>
      )}

      <Grid item xs={12}>
        <IncrementalityTable data={incrementality?.incrementalityBreakdown} />
      </Grid>

      <Grid item xs={12}>
        <Styled.SectionLabel>Campaign information</Styled.SectionLabel>
      </Grid>

      <Grid item xs={12} sm={6}>
        <StatCard
          data-intercom-target={'measurement-incrementality-media-spend'}
          title="Media spend"
          detail={metrics?.mediaSpend?.title ?? 'N/A'}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <StatCard
          data-intercom-target={'measurement-incrementality-cpm'}
          title="CPM"
          detail={metrics?.cpm?.title ?? 'N/A'}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <StatCard
          data-intercom-target={'measurement-incrementality-cpm'}
          title="Frequency"
          detail={metrics?.frequency?.title ?? 'N/A'}
        />
      </Grid>

      {/* //-- Charts component */}
      <Grid item xs={12} sm={6}>
        <div data-intercom-target={'measurement-overview-impressions'}>
          <StatCard
            title="Impressions"
            detail={impressions?.heroMetric?.title ?? 'N/A'}
          >
            {Number(impressions?.heroMetric?.title) !== 0 &&
              dataChartImpressions?.at(0)?.data && (
                <>
                  <MeasurementChart
                    data={dataChartImpressions}
                    label="impressions"
                  />

                  <Styled.Legend>
                    <div>
                      <Styled.Symbol>
                        <img src={LegendPoint} alt="" />
                      </Styled.Symbol>
                      <span>Running total impressions</span>
                    </div>

                    <div>
                      <Styled.Symbol>
                        <img src={LegendSquare} alt="" />
                      </Styled.Symbol>
                      <span>Daily impressions</span>
                    </div>
                  </Styled.Legend>
                </>
              )}
          </StatCard>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <div data-intercom-target={'measurement-overview-uniquereach'}>
          <StatCard title="Unique Reach" detail={reach?.heroMetric.title}>
            {Number(reach?.heroMetric?.title) !== 0 &&
              dataChartReach?.at(0)?.data && (
                <>
                  <MeasurementChart
                    data={dataChartReach}
                    label="unique reach"
                  />

                  <Styled.Legend>
                    <div>
                      <Styled.Symbol>
                        <img src={LegendPoint} alt="" />
                      </Styled.Symbol>
                      <span>Running total unique reach</span>
                    </div>

                    <div>
                      <Styled.Symbol>
                        <img src={LegendSquare} alt="" />
                      </Styled.Symbol>
                      <span>Daily unique reach</span>
                    </div>
                  </Styled.Legend>
                </>
              )}
          </StatCard>
        </div>
      </Grid>
    </>
  ) : (
    <Grid item xs={12}>
      <Alert severity="warning" title="Insufficient data warning">
        This campaign has not yet reached stability. Incrementality metrics will
        display once our confidence interval narrows.
      </Alert>
    </Grid>
  );
};

export default MeasurementIncrementality;
