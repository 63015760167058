import DataTableRow from './DataTableRow/DataTableRow';
import ExportButton from 'components/ExportButton/ExportButton';
import GhostLoader from 'react-ghost-loader';
import Pagination, {
  PaginationLoading,
} from 'components/Pagination/Pagination';
import React from 'react';
import { Alert, Card, Tooltip, theme } from '@klover/attain-design-system';
import { ArrowDown, ArrowUp, Info } from '@phosphor-icons/react';
import * as Styled from './DataTable.styles';

export interface DataTableRowType {
  component: (index: number) => React.ReactNode;
  sortValues: any;
}

interface DataTableProps {
  loading?: boolean;
  error?: boolean;
  title?: string | React.ReactNode;
  description?: string;
  columns: {
    title: string | React.ReactNode;
    sort: string;
    width?: string | number;
    tooltip?: string;
  }[];
  rows: DataTableRowType[] | undefined;
  rowDetails?:
    | {
        component: React.ReactNode;
        sortValues: any;
      }[]
    | undefined;
  sortBy?: string;
  defaultSortOrder?: 'asc' | 'desc';
  noFooter?: boolean;
  customActions?: React.ReactNode;
  noBorder?: boolean;
  noDataMessage?: string;
  noPagination?: boolean;
  onPaginationClick?: () => void;
  showRowsPerPageDropdown?: boolean;
  customRowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  id?: string;
  spacing?: string;
  footerLeft?: React.ReactNode;
}

const DataTable = ({
  loading,
  error,
  title,
  description,
  columns,
  rows = [],
  rowDetails,
  sortBy = 'percentOfAudience',
  defaultSortOrder = 'desc',
  noFooter,
  customActions,
  noBorder,
  noDataMessage,
  noPagination,
  onPaginationClick,
  showRowsPerPageDropdown,
  customRowsPerPageOptions,
  defaultRowsPerPage = 5,
  id,
  spacing,
  footerLeft,
}: DataTableProps) => {
  const [sortColumn, setSortColumn] = React.useState(sortBy);
  const [sortOrder, setSortOrder] = React.useState(defaultSortOrder);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [offset, setOffset] = React.useState(0);
  const handleSort = React.useCallback(
    (column: string) => {
      setSortOrder((prevOrder) =>
        sortColumn === column ? (prevOrder === 'asc' ? 'desc' : 'asc') : 'desc'
      );
      setSortColumn(column);
      setOffset(0);
    },
    [sortColumn]
  );

  const displayRows = React.useMemo(() => {
    return rows?.sort((a, b) => {
      const aValue = a.sortValues[sortColumn];
      const bValue = b.sortValues[sortColumn];
      return sortOrder === 'asc'
        ? aValue < bValue
          ? -1
          : 1
        : aValue > bValue
        ? -1
        : 1;
    });
  }, [rows, sortColumn, sortOrder]);

  const displayRowDetails = React.useMemo(() => {
    return rowDetails?.sort((a, b) => {
      const aValue = a.sortValues[sortColumn];
      const bValue = b.sortValues[sortColumn];
      return sortOrder === 'asc'
        ? aValue < bValue
          ? -1
          : 1
        : aValue > bValue
        ? -1
        : 1;
    });
  }, [rowDetails, sortColumn, sortOrder]);

  const paginatedRows = React.useMemo(() => {
    if (noPagination || displayRows.length <= rowsPerPage) {
      return displayRows;
    } else {
      return displayRows.slice(
        offset * rowsPerPage,
        offset * rowsPerPage + rowsPerPage
      );
    }
  }, [displayRows, offset, rowsPerPage, sortColumn, sortOrder]);

  const paginatedRowDetails = React.useMemo(() => {
    if (noPagination || displayRows.length <= rowsPerPage) {
      return displayRowDetails;
    } else {
      return displayRowDetails?.slice(
        offset * rowsPerPage,
        offset * rowsPerPage + rowsPerPage
      );
    }
  }, [displayRows, offset, rowsPerPage, sortColumn, sortOrder]);

  if (error) {
    return (
      <Card>
        <Alert severity="error">Error loading data</Alert>
      </Card>
    );
  }

  if (loading) {
    return (
      <Styled.Wrapper
        $noBorder={noBorder ?? false}
        data-testid="data-table-loading"
      >
        <Styled.TableWrapper $noBorder={noBorder ?? false} $spacing={spacing}>
          <thead>
            <tr>
              {columns.map((_, i) => (
                <th key={i} style={{ textAlign: i === 0 ? 'left' : 'center' }}>
                  <GhostLoader height="20px" heightRandom={0} />
                </th>
              ))}
              <th style={{ width: 1 }}>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {[...Array(5)].map((_, i) => (
              <tr key={i}>
                {columns.map((_, j) => (
                  <td key={j} style={{ textAlign: 'left' }}>
                    <GhostLoader height="24px" heightRandom={0} />
                  </td>
                ))}
                <td style={{ width: 1 }}>&nbsp;</td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={100}>
                <PaginationLoading />
              </td>
            </tr>
          </tfoot>
        </Styled.TableWrapper>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper $noBorder={noBorder ?? false} id={id}>
      {title && (
        <Styled.HeaderWrapper>
          <Styled.CardHeader>
            <Styled.CardTitle>{title}</Styled.CardTitle>

            {customActions}

            {id && <ExportButton divId={id} />}
          </Styled.CardHeader>

          {description && <Styled.CardDesc>{description}</Styled.CardDesc>}
        </Styled.HeaderWrapper>
      )}

      {rows.length === 0 ? (
        <p>
          <Alert severity="info">
            {noDataMessage ?? 'No data for this table'}
          </Alert>
        </p>
      ) : (
        <Styled.TableWrapper $noBorder={noBorder ?? false} $spacing={spacing}>
          <thead>
            <tr>
              {columns.map((column, i) => (
                <th
                  style={{ width: column.width ?? 'auto' }}
                  key={i}
                  onClick={() => handleSort(column.sort)}
                >
                  <Styled.ColHeader $align={i}>
                    {column.title}

                    {column.tooltip && (
                      <Tooltip title={column.tooltip} placement="top" arrow>
                        <Info weight="bold" color={theme.colors.textTertiary} />
                      </Tooltip>
                    )}

                    {sortColumn === column.sort &&
                      (sortOrder === 'asc' ? (
                        <ArrowUp
                          size={theme.icons.md}
                          color={theme.colors.textTertiary}
                        />
                      ) : (
                        <ArrowDown
                          size={theme.icons.md}
                          color={theme.colors.textTertiary}
                        />
                      ))}
                  </Styled.ColHeader>
                </th>
              ))}
              <th style={{ width: 1 }}>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {paginatedRows.map((row, i) => (
              <DataTableRow
                key={i}
                row={row.component}
                index={i} // Pass the current index
                details={
                  paginatedRowDetails
                    ? paginatedRowDetails[i]?.component
                    : undefined
                }
              />
            ))}
          </tbody>

          {!noFooter && (
            <tfoot>
              <tr>
                {footerLeft && <td>{footerLeft}</td>}
                <td colSpan={columns.length} onClick={onPaginationClick}>
                  {displayRows.length > 5 && !noPagination && (
                    <Pagination
                      total={displayRows.length}
                      setRowsPerPage={setRowsPerPage}
                      rowsPerPage={rowsPerPage}
                      setOffset={setOffset}
                      offset={offset}
                      hideDropdown={!showRowsPerPageDropdown}
                      rowsPerPageOptions={customRowsPerPageOptions}
                    />
                  )}
                </td>
              </tr>
            </tfoot>
          )}
        </Styled.TableWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default DataTable;
