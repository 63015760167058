import LiftDataNA from '../DataWarnings/liftDataNAThreshold';
import {
  BrandLiftBreakdownSkew,
  BrandLiftBreakdownSkewSegment,
  LiftSignificance,
} from 'types/graphql/generated';
import { BrandLiftSurveyDataWarning } from '../DataWarnings/dataWarning';
import { Datum } from '@nivo/line';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Info, Square } from '@phosphor-icons/react';
import { SkewsChart } from './SkewsChart';
import { Tooltip, theme } from '@klover/attain-design-system';
import * as Styled from '../LiftTable.styles';

export interface SkewData {
  title: string;
  description: string;
  skew: { value: number; title: string };
  significance: LiftSignificance; // might not have this field.
}

export interface SkewTableSection {
  title: string;
  skews: SkewData[];
}

export interface SkewChartDatum extends Datum {
  id: string;
  skew: number;
  color: string | null;
}

const getSkewChartDataFromLift = (
  data: BrandLiftBreakdownSkewSegment
): SkewChartDatum[] => {
  const chartData = [
    {
      id: data.title,
      skew: (data.skew?.value || 0) * 100,
      color:
        (data.skew?.value || 0) > 0
          ? theme.colors.successForeground
          : theme.colors.errorForeground,
    },
  ];
  return chartData;
};

interface Props {
  skewTables: BrandLiftBreakdownSkew[] | undefined;
  liftType: 'absoluteLift' | 'relativeLift';
}

export const SkewsTable = ({ skewTables, liftType }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number | null>(null);

  useEffect(() => {
    const onResize = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current?.offsetWidth);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [chartRef.current?.offsetWidth]);

  const skewsData = useMemo(() => {
    if (!skewTables) {
      return null;
    }
    return skewTables?.map((tableData) =>
      tableData.segments.map((data) => getSkewChartDataFromLift(data))
    );
  }, [skewTables]);

  return (
    <>
      {skewTables ? (
        <Styled.FullWidthGrid>
          {skewTables?.map((tableData, tableIdx) => (
            <Fragment key={tableData.id}>
              <Styled.HeadColOne>{tableData.title}</Styled.HeadColOne>
              <Styled.HeadColTwo>
                {tableIdx === 0 && (
                  <>
                    Index to [KPI]
                    <Tooltip
                      placement="top"
                      title="This shows how much each segment is driving lift compared to the average"
                    >
                      <Info />
                    </Tooltip>
                  </>
                )}
              </Styled.HeadColTwo>
              <Styled.HeadColThree>
                {tableIdx === 0 ? (
                  <Styled.ScaleWrapper $width={chartWidth}>
                    <div>-100%</div>
                    <div>-80%</div>
                    <div>-60%</div>
                    <div>-40%</div>
                    <div>-20%</div>
                    <div>0%</div>
                    <div>20%</div>
                    <div>40%</div>
                    <div>60%</div>
                    <div>80%</div>
                    <div>100%</div>
                  </Styled.ScaleWrapper>
                ) : (
                  <Styled.ScaleWrapper $width={chartWidth} />
                )}
              </Styled.HeadColThree>
              <Styled.HeadColFour />

              {tableData.segments.map((data, segIdx) => (
                <Fragment key={data.id}>
                  <Styled.ColOne>
                    <Styled.CellOneContent>{data.title}</Styled.CellOneContent>
                  </Styled.ColOne>
                  <Styled.ColTwo>
                    <span>{data.skew?.title ?? <LiftDataNA />}</span>
                  </Styled.ColTwo>
                  <Styled.ColThree>
                    <Styled.ChartWrapperSkew ref={chartRef}>
                      <SkewsChart
                        skewData={skewsData?.at(tableIdx)?.at(segIdx) || null}
                      />
                    </Styled.ChartWrapperSkew>
                  </Styled.ColThree>
                  <Styled.ColFour></Styled.ColFour>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </Styled.FullWidthGrid>
      ) : (
        <BrandLiftSurveyDataWarning />
      )}
      <Styled.Legend>
        <Styled.LegendItem>
          Skews positive
          <Square color={theme.colors.successForeground} weight="fill" />
        </Styled.LegendItem>
        <Styled.LegendItem>
          Skews negative
          <Square color={theme.colors.errorForeground} weight="fill" />
        </Styled.LegendItem>
      </Styled.Legend>
    </>
  );
};

export default SkewsTable;
