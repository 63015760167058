import ComingSoonBadge from 'components/ComingSoonBadge/ComingSoonBadge';
import DataCellHeatMap from 'components/DataCellHeatMap/DataCellHeatMap';
import Deals from '../Deals/Deals';
import MenuItemExport from 'components/MenuItemExport/MenuItemExport';
import Pagination from '../../../Pagination/Pagination';
import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { Card, Tooltip } from '@klover/attain-design-system';
import { ConversionRates } from 'types/graphql/generated';
import { OptimizationToggles } from '../../OptimizationsFilters/OptimizationsFilters';
import { removeCharsAndSpaces } from 'utils/format';
import * as Styled from '../../MeasurementOptimization/MeasurementOptimization.styles';

export enum SortingColumn {
  CONVERSIONRATE,
  IMPRESSIONVOLUME,
  ROAS,
}

interface Props {
  campaignTitle: string;
  data: ConversionRates[] | undefined;
  mainColumnName: string;
  tableTitle: string;
  hasDeals: boolean;
  decimalPlaces: number;
  columnToggles: OptimizationToggles;
}

export const OptimizationTable = ({
  campaignTitle,
  data,
  mainColumnName,
  tableTitle,
  hasDeals,
  decimalPlaces,
  columnToggles,
}: Props) => {
  const [sortAsc, setSortAsc] = React.useState(true);
  const [sortedData, setSortedData] = React.useState(data);
  const [sortingColumn, setSortingColumn] = React.useState(
    SortingColumn.CONVERSIONRATE
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [offset, setOffset] = React.useState(0);

  const featureflags = useFeatureFlags();
  const indexAllowed =
    featureflags.isReady &&
    featureflags.flags['MEASUREMENT_OPTIMIZATIONS_ANY_TOUCH'];
  const roasCS = useUiFlag('ui_optimizations_roas_coming_soon');
  const roasFF = useUiFlag('ui_optimizations_roas');
  const volumeFF = useUiFlag('ui_optimizations_volume');
  const allowRoas = roasFF.isReady && roasFF.enabled;
  const allowVolume = volumeFF.isReady && volumeFF.enabled;
  const showRoasCS = roasCS.isReady && roasCS.enabled;
  const footerColSPan =
    (columnToggles.CVR ? 1 : 0) +
    (columnToggles.impVol ? 1 : 0) +
    (allowRoas || showRoasCS ? 1 : 0) +
    (allowVolume ? 1 : 0);

  const handleSort = (
    fieldAccess: keyof ConversionRates,
    sortCol: SortingColumn
  ) => {
    const newSort = sortedData?.toSorted((a: any, b: any) => {
      const aVal = a[fieldAccess]?.value || 0;
      const bVal = b[fieldAccess]?.value || 0;
      return sortAsc ? aVal - bVal : bVal - aVal;
    });
    if (sortCol !== sortingColumn) {
      setSortingColumn(sortCol);
      newSort?.reverse();
    } else {
      setSortAsc(!sortAsc);
    }
    setSortedData(newSort);
  };

  let maxIndex;
  if (indexAllowed) {
    maxIndex = data
      ? data?.reduce((acc, curr) => {
          const totalIndexValue = curr.totalIndexValue?.value;
          return totalIndexValue > acc ? totalIndexValue : acc;
        }, 0)
      : 100;
  } else {
    maxIndex = data
      ? data?.reduce((acc, curr) => {
          const totalConversionRate = curr.totalConversionRate?.value;
          return totalConversionRate > acc ? totalConversionRate : acc;
        }, 0)
      : 100;
  }

  const maxIndexVolume = data
    ? data.reduce((acc, curr) => {
        const totalVolumeValue = curr?.impressionVolume?.value ?? 0;
        return totalVolumeValue > acc ? totalVolumeValue : acc;
      }, 0)
    : 100;

  const displayData = sortedData?.slice(
    offset * rowsPerPage,
    offset * rowsPerPage + rowsPerPage
  );

  const rowsExportData = data?.map((row) => [
    row.rank,
    row.conversionTypeName,
    indexAllowed ? row.totalIndexValue.title : row.totalConversionRate.title,
  ]);

  const exportData = [
    [`Campaign Name: ${campaignTitle}`],
    [`Table Name: ${tableTitle}`],
    [`Date: ${new Date()}`],
    [''],
    ['Rank', mainColumnName, 'Conversion rate'],
    ...(rowsExportData as []),
  ];

  const menu = [
    <MenuItemExport
      data={exportData}
      filename={`${removeCharsAndSpaces(campaignTitle)}_${removeCharsAndSpaces(
        tableTitle
      )}_${new Date()}.csv`}
      key="export"
    />,
  ];

  return (
    <Card menu={menu}>
      <Styled.TableHeader>{tableTitle}</Styled.TableHeader>

      <Styled.Table>
        <thead>
          <tr>
            {hasDeals ? (
              <>
                <th>{mainColumnName}</th>
                <Styled.HeaderFull>Deal name</Styled.HeaderFull>
              </>
            ) : (
              <Styled.HeaderFull>{mainColumnName}</Styled.HeaderFull>
            )}

            {columnToggles.CVR && (
              <Styled.HeaderCVR
                onClick={() => {
                  handleSort(
                    indexAllowed ? 'totalIndexValue' : 'totalConversionRate',
                    SortingColumn.CONVERSIONRATE
                  );
                }}
              >
                Conversion rate {indexAllowed && 'index'}
                {sortingColumn === SortingColumn.CONVERSIONRATE && (
                  <Styled.RankArrow weight="bold" $sortAsc={sortAsc} />
                )}
              </Styled.HeaderCVR>
            )}

            {allowVolume && columnToggles.impVol && (
              <Styled.HeaderCVR
                onClick={() => {
                  handleSort(
                    'impressionVolume',
                    SortingColumn.IMPRESSIONVOLUME
                  );
                }}
              >
                Impression volume
                {sortingColumn === SortingColumn.IMPRESSIONVOLUME && (
                  <Styled.RankArrow weight="bold" $sortAsc={sortAsc} />
                )}
              </Styled.HeaderCVR>
            )}
            {columnToggles.ROAS && (allowRoas || showRoasCS) && (
              <Styled.HeaderROAS>
                ROAS
                {sortingColumn === SortingColumn.ROAS && (
                  <Styled.RankArrow weight="bold" $sortAsc={sortAsc} />
                )}
                {showRoasCS && (
                  <Tooltip
                    title={
                      <div>
                        <h3>Action required to view</h3>
                        <div>
                          Send us your media spend data & we’ll populate this
                          column
                        </div>
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <ComingSoonBadge />
                  </Tooltip>
                )}
              </Styled.HeaderROAS>
            )}
          </tr>
        </thead>
        <tbody>
          {displayData?.map((row) => {
            let rowValue;
            if (indexAllowed) {
              rowValue = ((row.totalIndexValue?.value || 0) / maxIndex).toFixed(
                decimalPlaces
              );
            } else {
              rowValue = (row.totalConversionRate?.value || 0) / maxIndex;
            }
            const rowValueNumber = Number(rowValue);
            const rowVolumeNumber = Number(
              (row?.impressionVolume?.value || 0) / maxIndexVolume
            );

            return (
              <tr key={row.rank}>
                <Styled.CellTwoCol>{row.conversionTypeName}</Styled.CellTwoCol>

                {row.dealConversions && hasDeals ? (
                  <Deals
                    deals={row.dealConversions}
                    index={
                      indexAllowed
                        ? row.totalIndexValue
                        : row.totalConversionRate
                    }
                    indexVolume={row?.totalIndexValue}
                    indexRoas={row?.roas ?? undefined}
                    maxIndexVolume={maxIndexVolume}
                    maxIndex={maxIndex}
                    decimalPlaces={decimalPlaces}
                  />
                ) : (
                  <>
                    {columnToggles.CVR && (
                      <td>
                        <Styled.CellRate>
                          <DataCellHeatMap
                            text={
                              (indexAllowed
                                ? (row.totalIndexValue?.value * 100).toFixed(
                                    decimalPlaces
                                  )
                                : (
                                    row.totalConversionRate?.value * 100
                                  ).toFixed(decimalPlaces)) + '%'
                            }
                            indexValue={rowValueNumber}
                          />
                        </Styled.CellRate>
                      </td>
                    )}
                    {allowVolume && columnToggles.impVol && (
                      <td>
                        <Styled.CellRate>
                          {row?.impressionVolume?.value ? (
                            <DataCellHeatMap
                              text={`${(
                                row.impressionVolume.value * 100
                              ).toFixed(decimalPlaces)}%`}
                              indexValue={rowVolumeNumber}
                            />
                          ) : (
                            '--'
                          )}
                        </Styled.CellRate>
                      </td>
                    )}
                    {columnToggles.ROAS && (allowRoas || showRoasCS) && (
                      <td>
                        <Styled.CellRate>
                          {allowRoas && row?.roas?.title
                            ? row.roas.title
                            : '--'}
                        </Styled.CellRate>
                      </td>
                    )}
                  </>
                )}
              </tr>
            );
          })}
        </tbody>

        {data?.length && data?.length > 5 && (
          <tfoot>
            <td colSpan={footerColSPan}>
              <Pagination
                total={data?.length ?? 0}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                setOffset={setOffset}
                offset={offset}
              />
            </td>
          </tfoot>
        )}
      </Styled.Table>
    </Card>
  );
};

export default OptimizationTable;
