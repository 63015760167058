import { Datum, Layer, ResponsiveLine, Serie } from '@nivo/line';
import { Square } from '@phosphor-icons/react';
import { generateYTicksCustomInterval } from 'utils/Measurement/generateYTicksArray';
import { theme } from '@klover/attain-design-system';
import { useRef } from 'react';
import * as ChartStyles from 'views/Measurement/MeasurementChart.styles';
import * as Styled from 'views/Measurement/MeasurmentIncrementalityChart.styles';
import * as d3 from 'd3-shape';

const LOWYAXIS = 0;
const HIGHYAXIS = 50;

export interface TrendsChartDatum extends Datum {
  ci: { lower: number | undefined; upper: number | undefined };
  ciDelta: number;
}

export interface TrendsDataChart {
  id: string;
  color: string;
  startDate: string | null;
  midDate: string | null;
  endDate: string | null;
  data: TrendsChartDatum[] | null;
}

interface Props {
  data: TrendsDataChart;
  ciBounds: [TrendsDataChart | null, TrendsDataChart | null];
}

export const TrendsChart = ({ data, ciBounds }: Props) => {
  const lineTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 16,
          fill: theme.colors.textBrand,
        },
      },
    },
    grid: {
      line: {
        stroke: '#D9D9D9',
      },
    },
  };

  const chartRef = useRef<HTMLDivElement>(null);
  const overallMargin = 69;

  const upperData = ciBounds?.at(0)?.data ? ciBounds?.at(0)?.data : [];
  const lowerData = ciBounds?.at(1)?.data ? ciBounds?.at(1)?.data : [];
  const chartData: Serie[] = [
    { id: 'Incrementality', data: data?.data ?? [] },
    {
      id: 'Upper ci',
      data: upperData ?? [],
    },
    { id: 'Lower ci', data: lowerData ?? [] },
  ];
  const highYvals: number[] =
    ciBounds?.at(0)?.data?.map((i) => (typeof i.y === 'number' ? i.y : 0)) ||
    [];
  const lowYvals: number[] =
    ciBounds?.at(1)?.data?.map((i) => (typeof i.y === 'number' ? i.y : 0)) ||
    [];

  const highestY = highYvals.length ? Math.max(...highYvals) : 0;
  const lowestY = lowYvals.length ? Math.min(...lowYvals) : 0;
  const ticksY: number[] = generateYTicksCustomInterval(
    10,
    lowestY,
    highestY,
    LOWYAXIS,
    HIGHYAXIS,
    4
  );

  const CiLayer = ({ series, yScale }) => {
    const line1 = series.find((s) => s.id === 'Upper ci');
    const line2 = series.find((s) => s.id === 'Lower ci');

    if (!line1 || !line2) {
      return null;
    }
    const areaGenerator = d3
      .area()
      .x((d: any) => d.position.x)
      .y0((d: any) => yScale(d.yBottom))
      .y1((d: any) => yScale(d.yTop))
      .curve(d3.curveMonotoneX);
    const areaData = line1.data.map((d, i) => ({
      ...d,
      yBottom: line2.data[i].data.y,
      yTop: line1.data[i].data.y,
    }));

    const d = areaGenerator(areaData);
    return (
      <path d={d ?? undefined} fill="hsla(254, 29%, 48%, 0.15)" stroke="none" />
    );
  };

  return (
    <Styled.Wrapper ref={chartRef}>
      <ChartStyles.BottomAxis>
        <span>{<>{data?.startDate}</>}</span>
        <span>{<>{data?.midDate}</>}</span>
        <span>{<>{data?.endDate}</>}</span>
      </ChartStyles.BottomAxis>

      <Styled.ChartWrapper>
        <ResponsiveLine
          useMesh={false}
          animate={false}
          axisLeft={{
            tickSize: 0,
            tickPadding: 18,
            format: (val) => val + '%',
            tickValues: [...ticksY],
          }}
          axisBottom={null}
          theme={lineTheme}
          colors={[
            theme.colors.CHART_PRIMARY,
            'hsla(254, 29%, 48%, 0.1)',
            'hsla(254, 29%, 48%, 0.1)',
          ]}
          data={chartData}
          enableGridX={false}
          enableGridY={true}
          enableSlices="x"
          gridYValues={[...ticksY]}
          curve="monotoneX"
          yScale={{
            type: 'linear',
            min: lowestY < LOWYAXIS ? lowestY : LOWYAXIS,
            max: highestY > HIGHYAXIS ? highestY : HIGHYAXIS,
            stacked: false,
            reverse: false,
          }}
          margin={{
            top: 12,
            right: overallMargin,
            bottom: 36,
            left: overallMargin,
          }}
          pointSize={0}
          sliceTooltip={(data) => {
            const upper = data.slice.points[1];
            const inc = data.slice.points[2] ?? data.slice.points[0];
            const date = data.slice.points[0].data.xFormatted;
            const yVal = Number(inc.data.y);
            const percentage = yVal.toFixed(2);

            const ci = inc.data['ciDelta'];
            return (
              <ChartStyles.Tooltip>
                <ChartStyles.LabelWrapper>
                  <Square size={18} fill={inc.color} weight="fill" />
                  Lift
                  <ChartStyles.Label>{`${percentage}%`}</ChartStyles.Label>
                </ChartStyles.LabelWrapper>
                <ChartStyles.LabelWrapper>
                  <Square size={18} fill={upper?.color} weight="fill" />
                  CI delta
                  <ChartStyles.Label>{`+/- ${ci?.toFixed(
                    2
                  )}%`}</ChartStyles.Label>
                </ChartStyles.LabelWrapper>
                <ChartStyles.LabelWrapper>
                  <ChartStyles.Label>{date}</ChartStyles.Label>
                </ChartStyles.LabelWrapper>
              </ChartStyles.Tooltip>
            );
          }}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            CiLayer as Layer,
            'lines',
            'slices',
            'points',
            'mesh',
            'legends',
          ]}
        />
      </Styled.ChartWrapper>
    </Styled.Wrapper>
  );
};
