import React from 'react';
import { Export } from '@phosphor-icons/react';
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  theme,
} from '@klover/attain-design-system';
import { useExport } from 'hooks/useExport/useExport';

interface ExportButtonProps {
  disabled?: boolean;
  divId: string;
  tooltip?: string;
}

export const ExportButton = ({
  disabled,
  divId,
  tooltip,
}: ExportButtonProps) => {
  const [exportAnchorEl, setExportAnchorEl] = React.useState(null);
  const { exportDivToPdf, exportDivToImage } = useExport();
  const exportOpen = Boolean(exportAnchorEl);

  const handleExportMenuClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setExportAnchorEl(null);
  };

  const handleExportClick = (type) => {
    if (type === 'pdf') {
      exportDivToPdf(divId, `${divId}.pdf`);
    } else {
      exportDivToImage(divId, `${divId}.png`);
    }
    handleCloseMenu();
  };

  return (
    <>
      <Tooltip title={tooltip ?? 'Export'} placement="top" arrow>
        <IconButton
          data-html2canvas-ignore
          id="export"
          aria-controls={exportOpen ? 'export-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={exportOpen ? 'true' : undefined}
          disabled={disabled}
          onClick={handleExportMenuClick}
        >
          <Export size={theme.icons.lg} />
        </IconButton>
      </Tooltip>

      <Menu
        id="export-menu"
        anchorEl={exportAnchorEl}
        open={exportOpen}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'export' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => handleExportClick('pdf')}>
          Export to PDF
        </MenuItem>
        <MenuItem onClick={() => handleExportClick('png')}>
          Export to PNG
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportButton;
