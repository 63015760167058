import { LiftChartDatum, chartCommonProps } from './LiftTable';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { theme } from '@klover/attain-design-system';

interface Props {
  liftData: LiftChartDatum[] | undefined;
}

export const LiftsChart = ({ liftData }: Props) => {
  return (
    <ResponsiveBarCanvas
      {...chartCommonProps}
      // defs={[
      //   linearGradientDef(
      //     'gradientExposed',
      //     [
      //       { offset: 0, color: theme.colors.CHART_PRIMARY },
      //       { offset: 100, color: '#67579E33' },
      //     ],
      //     {
      //       gradientTransform: 'rotate(90 0.5 0.5)',
      //     }
      //   ),
      //   linearGradientDef(
      //     'gradientCrontrol',
      //     [
      //       { offset: 0, color: theme.colors.CHART_TERTIARY },
      //       { offset: 100, color: '#22DDD333' },
      //     ],
      //     {
      //       gradientTransform: 'rotate(90 0.5 0.5)',
      //     }
      //   ),
      // ]}
      // fill={[
      //   {
      //     match: (d) => !!(d.data.data.type === 'exposed'),
      //     id: 'gradientExposed',
      //   },
      //   { match: '*', id: 'gradientCrontrol' },
      // ]}
      enableGridX
      enableLabel={false}
      isInteractive={false}
      axisBottom={null}
      enableGridY={false}
      data={liftData || []}
      keys={['lift']}
      label={''}
      layers={[
        'grid',
        'axes',
        // Custom layer for background bar that always fills to 100%
        // ({ bars, width }) =>
        //   bars.map((bar) => (
        //     <rect
        //       key={bar.key}
        //       x={bar.x}
        //       y={bar.y}
        //       width={width}
        //       height={bar.height}
        //       fill={
        //         bar.data.data.type === 'exposed' ? '#67579E0D' : '#22DDD326'
        //       }
        //     />
        //   )),
        (ctx, { bars, width }) => {
          bars.forEach((bar) => {
            ctx.fillStyle =
              bar.data.data.type === 'exposed' ? '#67579E0D' : '#22DDD326';
            ctx.fillRect(bar.x, bar.y, width, bar.height);
          });
        },
        'bars',
        (ctx, { bars, xScale }) => {
          bars.forEach((bar) => {
            const xStart = xScale(Number(bar.data.data['ciLower']) ?? 0);
            const xEnd = xScale(Number(bar.data.data['ciUpper']) ?? 0);
            const rectWidth = xEnd - xStart;
            ctx.fillStyle = theme.colors.backgroundBrand;
            ctx.fillRect(xStart, bar.y + bar.height / 2 - 2.5, rectWidth, 5);
          });
        },
        // ({ bars, xScale }) =>
        //   bars.map((bar) => {
        //     const xStart = xScale(bar.data.data.ciLower);
        //     const xEnd = xScale(bar.data.data.ciUpper);
        //     return (
        //       <rect
        //         key={bar.key}
        //         x={xStart}
        //         y={bar.y + bar.height / 2 - 2.5}
        //         width={xEnd - xStart}
        //         height="5px"
        //         fill={theme.colors.backgroundBrand}
        //       />
        //     );
        //   }),
        'legends',
      ]}
    />
  );
};
