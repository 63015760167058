import { gql } from '@apollo/client';

export const brandLiftSurveyCampaignTrendsQuery = gql`
  query BrandLiftSurveyInsightsCampaignTrends(
    $input: BrandLiftSurveyInsightsInput!
  ) {
    brandLiftSurveyInsightsCampaignTrends(input: $input) {
      id
      title
      timeseries {
        absoluteLift {
          value
          title
        }
        ci {
          upper {
            value
            title
          }
          lower {
            value
            title
          }
        }
        ts
      }
    }
  }
`;

export default brandLiftSurveyCampaignTrendsQuery;
