import { gql } from '@apollo/client';

export const brandLiftSurveyInsightsOverallQuery = gql`
  query BrandLiftSurveyInsightsOverall($input: BrandLiftSurveyInsightsInput!) {
    brandLiftSurveyInsightsOverall(input: $input) {
      id
      title
      segments {
        id
        title
        description
        absoluteLift {
          value
          title
        }
        exposed {
          value {
            title
            value
          }
          ci {
            upper {
              value
              title
            }
            lower {
              value
              title
            }
          }
        }
        control {
          value {
            value
            title
          }
          ci {
            upper {
              value
              title
            }
            lower {
              value
              title
            }
          }
        }
        significance
      }
    }
  }
`;

export default brandLiftSurveyInsightsOverallQuery;
