import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const FullWidthGrid = styled.div`
  width: calc(100% + ${theme.spacing.lg} * 2);
  margin-inline-start: -${theme.spacing.lg};
  display: grid;
`;

export const ColOne = styled.div`
  min-width: 350px;
  max-width: 400px;
  border-block: 1px solid ${theme.colors.borderLight};
  grid-column: 1 / 3;
  align-content: center;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
`;
export const ColTwo = styled.div`
  ${theme.typography.bodyMD};
  border-block: 1px solid ${theme.colors.borderLight};
  grid-column: 3 / 5;
  text-align: center;
  align-content: center;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  span {
    margin-inline: ${theme.spacing.xs};
  }
`;
export const ColThree = styled.div`
  border-block: 1px solid ${theme.colors.borderLight};
  grid-column: 5 / 10;
  min-width: 0; // hack to keep chart from expanding to infinity...
  padding-inline-start: ${theme.spacing.sm};
`;
export const ColFour = styled.div`
  grid-column: 10 / 12;
  min-width: 50px;
  ${theme.typography.bodySM};
  padding-inline: ${theme.spacing.sm};
  border-block: 1px solid ${theme.colors.borderLight};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const HeadColOne = styled(ColOne)`
  background-color: ${theme.colors.backgroundLight};
  ${theme.typography.bodyMD};
  color: ${theme.colors.textSecondary};
`;
export const HeadColTwo = styled(ColTwo)`
  background-color: ${theme.colors.backgroundLight};
  ${theme.typography.bodyMD};
  color: ${theme.colors.textSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-inline: ${theme.spacing.xxs};
  }
`;
export const HeadColThree = styled(ColThree)`
  background-color: ${theme.colors.backgroundLight};
  position: relative;
  padding-inline: 0;
`;
export const HeadColFour = styled(ColFour)`
  background-color: ${theme.colors.backgroundLight};
`;

export const ScaleWrapper = styled.div<{ $width: number | null }>`
  ${theme.typography.bodySM}
  color: ${theme.colors.textTertiary};
  height: 100%;
  width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
  padding-inline: 0;
  margin-inline-start: ${theme.spacing.xxs};
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;

  & > div {
    position: absolute;

    ${({ children }) =>
      Array.isArray(children) &&
      children?.map(
        (_, index) => `
        //nth child is 1 indexed so we add 1 to map index to get child.
        &:nth-child(${index + 1}) {
          left: ${index * 10}%;
        }
      `
      )}
  }
`;
export const ChartWrapper = styled.div`
  height: 100px;
  min-width: 0;
  position: relative;
`;

export const ChartWrapperSkew = styled.div`
  height: 65px;
  min-width: 0;
  position: relative;
`;

export const CellOneContent = styled.div`
  ${theme.typography.bodyLG};
  div {
    ${theme.typography.bodySM};
    color: ${theme.colors.textTertiary};
  }
`;

export const Legend = styled.div`
  ${theme.typography.bodySM};
  width: 100%;
  color: ${theme.colors.textTertiary};
  padding-block: ${theme.spacing.lg};
  display: flex;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: ${theme.spacing.lg};
  margin-block-end: -${theme.spacing.lg};
  svg {
    margin-inline-start: ${theme.spacing.xxs};
  }
`;
