import InterestSkews from './InterestSkews/InterestSkews';
import Overall from './Overall/Overall';
import Tabs from 'components/Tabs/Tabs';
import Trends from './Trends/Trends';
import brandLiftSurveyQuestionTypesQuery from 'operations/queries/brandLiftSurveyInsightsQuestionTypes.query';
import useSearchParams from 'hooks/useSearchParams';
import { Audiences } from './Audiences/Audience';
import {
  BlquestionType,
  BrandLiftSurveyInsightsQuestionTypesQuery,
  BrandLiftSurveyInsightsQuestionTypesQueryVariables,
} from 'types/graphql/generated';
import {
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@klover/attain-design-system';
import { CardTitle } from '../Measurement.styles';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementInsightsDashboard';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import * as Styled from './MeasurementBrandSurveyV2.styles';

enum TabRoutes {
  OVERALL,
  AUDIENCE,
  SKEWS,
  TRENDS,
  FREQUENCY,
  PERFORMANCE,
}

export const MeasurementBrandSurveyV2 = () => {
  // const [liftType, setLiftType] = useState<'Absolute lift' | 'Relative lift'>(
  //   'Absolute lift'
  // );
  // TODO: couple things we need merchant or brand survey --
  //

  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const [selectedTab, setSelectedTab] = useState(TabRoutes.OVERALL);
  const [questionType, setQuestionType] = useState<BlquestionType | null>(null);

  const { data, loading } = useQuery<
    BrandLiftSurveyInsightsQuestionTypesQuery,
    BrandLiftSurveyInsightsQuestionTypesQueryVariables
  >(brandLiftSurveyQuestionTypesQuery, {
    variables: { input: { campaignId: searchParams.campaignId } },
    onCompleted(data) {
      setQuestionType(
        data.brandLiftSurveyInsightsQuestionTypes.questionTypes[0]
      );
    },
  });

  const questionTypeOptions =
    data?.brandLiftSurveyInsightsQuestionTypes.questionTypes;
  const isBrand = data?.brandLiftSurveyInsightsQuestionTypes.isBrand;

  return (
    <Styled.Wrapper>
      <Card>
        <Styled.CardHeader>
          <Grid container>
            <Grid item xs={8}>
              <CardTitle>Brand lift breakdown</CardTitle>
            </Grid>
            <Grid item xs={4}>
              {selectedTab !== TabRoutes.OVERALL && (
                <Grid container justifyItems="flex-end" alignItems="center">
                  <Grid item xs={3} textAlign="center">
                    <InputLabel>Showing:</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      fullWidth
                      renderValue={() =>
                        !loading && !!questionType
                          ? questionType.title
                          : 'loading...'
                      }
                      value={questionType?.templateId || null}
                      onChange={(e) => {
                        setQuestionType(
                          questionTypeOptions?.find(
                            (qt) => qt.templateId === e.target.value
                          ) || null
                        );
                      }}
                    >
                      {loading
                        ? []
                        : questionTypeOptions?.map((option) => (
                            <MenuItem
                              key={option.templateId}
                              value={option.templateId}
                              selected={
                                option.templateId === questionType?.templateId
                              }
                            >
                              {option.title}
                            </MenuItem>
                          ))}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* <ButtonGroup>
            <Button
              variant={liftType === 'Absolute lift' ? 'contained' : 'outlined'}
              onClick={() => {
                setLiftType('Absolute lift');
              }}
            >
              Absolute lift
            </Button>
            <Button
              variant={liftType === 'Relative lift' ? 'contained' : 'outlined'}
              onClick={() => {
                setLiftType('Relative lift');
              }}
            >
              Relative lift
            </Button>
          </ButtonGroup> */}
          </Grid>
        </Styled.CardHeader>
        <Tabs
          tabs={[
            {
              title: 'Overall result',
              active: selectedTab === TabRoutes.OVERALL,
              onClick: () => {
                setSelectedTab(TabRoutes.OVERALL);
              },
            },
            {
              title: 'Audience',
              active: selectedTab === TabRoutes.AUDIENCE,
              onClick: () => {
                setSelectedTab(TabRoutes.AUDIENCE);
              },
            },
            {
              title: 'Interest skews',
              active: selectedTab === TabRoutes.SKEWS,
              onClick: () => {
                setSelectedTab(TabRoutes.SKEWS);
              },
            },
            {
              title: 'Campaign trends',
              active: selectedTab === TabRoutes.TRENDS,
              onClick: () => {
                setSelectedTab(TabRoutes.TRENDS);
              },
            },
            // {
            //   title: 'Frequency',
            //   active: selectedTab === TabRoutes.FREQUENCY,
            //   onClick: () => {
            //     setSelectedTab(TabRoutes.FREQUENCY)
            //   },
            // },
            // {
            //   title: 'Media performance',
            //   active: selectedTab === TabRoutes.PERFORMANCE,
            //   onClick: () => {
            //     setSelectedTab(TabRoutes.PERFORMANCE)
            //   },
            // },
          ]}
        />
        <Styled.CardBody>
          {selectedTab === TabRoutes.OVERALL && (
            <Overall
              campaignId={searchParams.campaignId}
              liftType="absoluteLift"
            />
          )}
          {selectedTab === TabRoutes.AUDIENCE && (
            <Audiences
              questionType={questionType}
              campaignId={searchParams.campaignId}
              liftType="absoluteLift"
              isBrand={!!isBrand}
            />
          )}

          {selectedTab === TabRoutes.SKEWS && (
            <InterestSkews
              questionType={questionType}
              campaignId={searchParams.campaignId}
              liftType="absoluteLift"
              isBrand={!!isBrand}
            />
          )}
          {selectedTab === TabRoutes.TRENDS && (
            <Trends
              questionType={questionType}
              campaignId={searchParams.campaignId}
              isBrand={!!isBrand}
            />
          )}
          {/* <Route
              path={paths.BRAND_SURVEY_FREQUENCY}
              element={<>Frequency</>}
            />
            <Route
              path={paths.BRAND_SURVEY_PERFORMANCE}
              element={<>Media performance</>}
            /> */}
        </Styled.CardBody>
      </Card>
    </Styled.Wrapper>
  );
};

export default MeasurementBrandSurveyV2;
